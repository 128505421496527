






























































































































































































































.modal-content {
  color: #00f2c3;
  background-color: #000000 !important;
}

address {
  color: #00f2c3;
}
